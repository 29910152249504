#visimisi h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
}

#visimisi p {
  margin-bottom: 20px;
}

@media screen and (max-width: 1025px) {
  #visimisi {
    padding: 0 10px;
  }

  #visimisi p {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  #visimisi {
    text-align: center;
  }
}
