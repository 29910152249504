#achivement {
  background-color: #272727;
  padding: 30px;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
}

#achivement h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  color: #ffce07;
  /* max-width: 800px; */
  margin: 10px auto;
}

@media screen and (max-width: 770px) {
  #achivement h1 br {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #achivement h1 {
    font-size: 26px;
  }
}

#achivement h2 span{
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 46px;
  line-height: 63px;
}

#achivement h3 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
}
