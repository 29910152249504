.carousel-btn {
  width: 22%;
  height: 84px;
  background: #e5e5e5;
  border-radius: 8px;
}

.carousel-btn:hover {
  background: #ffce07;
}

.carousel-btn img{
  margin-right: 15px;
}

/* .service-carousel .active {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.service-caroiusel-content {
  font-family: "Nunito", sans-serif;
}

.content-list {
  background-color: #ffce07;
  width: 80%;
  height: 100%;
  padding: 30px;
  border-radius: 8px;
  margin-left: auto;
}

.content-list h3 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #272727;
  margin-bottom: 20px;
}

.content-list ul {
  list-style: none;
  padding: 0;
}

.content-list li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #272727;
  margin-bottom: 20px;
}

.content-list li img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-left: 0;
}

@media screen and (max-width: 770px) {
  .content-list {
    width: 100%;
  }
  .carousel-item{
    padding: 0 20px;
  }
  .carousel-btn{
    font-size: 12px;
    text-align: center;
  }

  .carousel-btn img{
    width: 20px;
    margin: 0 auto;
  }
}
