/* 
.background-yellow {
  width: 886px;
  height: 474px;
  background: #ffce07;
  margin-left: auto;
  border-radius: 8px;
  position: relative;
} */

#process .caraousel {
  background-image: url("../../Asset/bgProcess.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
}

#process .carousel-item img {
  height: 350px;
  width: 470px;
}

.content {
  padding: 7% 6%;
}

.illustrator {
  display: flex;
  flex-direction: row;
}

.process-btn {
  margin-top: 50px;
}

#process .carousel-item h3,
#process .carousel-item p {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  #process .carousel-item img {
    height: calc(350px / 100 * 60);
    width: calc(470px / 100 * 60);
  }
}

@media screen and (max-width: 767px) {
  .process-btn {
    margin-top: 0px;
  }

  .carousel-item h3 {
    font-size: 16px;
  }

  .carousel-item p {
    font-size: 11px;
  }

  .content {
    padding: 10% 6% 7%;
  }

  #process .caraousel {
    background-image: url("../../Asset/bgProcessMobile.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
  }

  .carousel-img {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  #process .carousel-item img {
    height: calc(350px / 100 * 50);
    width: calc(470px / 100 * 50);
  }
}

@media screen and (max-width: 425px) {
  #process .carousel-item img {
    height: calc(350px / 100 * 30);
    width: calc(470px / 100 * 30);
  }
  .carousel-item h3,
  .carousel-item p {
    font-size: 11px;
  }
}
