.serviceCard h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #272727;
}

.serviceCard p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}
