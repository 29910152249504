.under-construction {
  height: 120vh;
  background-image: url("../../Asset/bg-underConstruction.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.under-construction h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 76px;
  color: #ffce07;
  margin-bottom: 40px;
}

.under-construction img {
  margin-top: 100px;
  width: 40%;
}

@media screen and (max-width: 1025px) {
  .under-construction h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 770px) {
    .under-construction h1 {
        font-size: 28px;
      }
}
