#maintenance .service-hero{
    background-image: url('../../../../public/Asset/Services/Digital\ Maintenance/Header.jpg');  
    background-repeat: no-repeat;
    background-size: cover;  
}

#maintenance .service-hero h1{
    color: #FFCE07;
}

#maintenance .service-hero p{
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}