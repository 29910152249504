/* modification carousel from bootstrap */
.carousel-item img{
    width: 200px;
    height: 200px;
    margin: 0 20px;
}
.carousel .carousel-indicators button {
  width: 13px;
  height: 13px;
  background-color: #C4C4C4 ;
  border-radius: 50%;
  margin: 0 10px;
  position: relative;
  top: 60px;
}

.carousel-indicators .active{
    background-color: #FFCE07 !important;
}

@media screen and (max-width: 1000px) {
    .carousel-item img{
        width: 150px;
        height: 150px;
        margin: 0 ;
    }
}

@media screen and (max-width: 425px) {
    .carousel-item img{
        width: 75px;
        height: 75px;
        margin: 0 ;
    }
}