@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  font-family: "SF Pro Display", sans-serif;
}

/* For all title section */

section {
  padding-top: 100px;
}

.content-img {
  display: block;
  margin-left: auto;
  max-width: 100%;
}

/* Set component for smartphone */

@media screen and (max-width: 1025px) {
  .content-img {
    margin: 0 auto;
  }
}

@media screen and (max-width: 425px) {
  .title-section h1 {
    font-size: 26px;
    line-height: 31px;
    color: #272727;
  }
}
