.btn-yellow {
  font-weight: bold;
  width: 188px;
  height: 57px;
  background-color: #ffce07;
  border-radius: 30px;
}

.btn-yellow:hover {
  opacity: 0.7;
}

.wa-btn {
  position: fixed;
  right: 0px;
  bottom: 30px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 10;
}

.circle {
  background-color: #25d366;
  position: fixed;
  right: 0px;
  bottom: 30px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 6;
  animation: sonarWave 2.5s infinite;
  animation-delay: 0.1s;
  transition: 5s ease;
}

@keyframes sonarWave {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.text_icon {
  border-radius: 12px;
  width: 96px;
  padding: 5px;
  background: #fff;
  border-color: black;
  position: fixed;
  right: 10px;
  bottom: 0px;
}

@media screen and (max-width: 1025px) {
  .wa-btn,
  .circle {
    right: 0;
    bottom: 27px;
  }

  .text_icon {
    right: 12px;
    bottom: 0;
  }
}
