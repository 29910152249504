.navbar {
  align-items: center;
  justify-content: space-between;
  height: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

.navbar-logo {
  animation-name: example;
  animation-duration: 1.5s;
}

@keyframes example {
  0% {
    margin-bottom: 50px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar-nav {
  animation-name: example;
  animation-duration: 1.5s;
}

.nav-item {
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.nav-link {
  color: #021718 !important;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  padding: 8px 15px !important;
}

.menu-icon {
  display: none;
}

.btn-nav {
  background: #ffce07;
  border-radius: 30px;
  width: 188px;
  height: 57px;
  font-weight: bold;
  font-size: 18px;
}

.ddown {
  position: absolute;
  top: 100px;
  
  background-color: #fff;
  border-width: 1px;
  border-color: #021718;
  display: none;
  width: 530px;
  list-style: none;
  padding: 0;
}

.active {
  display: flex;
}

.ddown .nav-link {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #272727 ;
  text-decoration: none;
}

.nav-link:hover,
.nav-link:active {
  color: #ffce07 !important;
}


@media screen and (max-width: 995px) {
  .navbar-logo {
    margin: 0 20px;
    font-size: 32px;
  }
  .navbar-nav {
    display: flex;
    text-align: center;
    width: 100%;
    height: calc(100vh - 100px);
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
    align-items: center;
    z-index: 90;
  }

  header {
    width: 100%;
    max-width: 1200px;
  }

  .navbar-nav.active {
    background: #fbeeac;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #f4d160;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-item {
    text-align: center;
    margin: 5px auto;
    height: auto;
  }

  .fa-times {
    font-size: 2rem;
    margin-right: 3px;
  }

  .dd {
    display: block;
  }

  .ddown {
    position: relative;
    background-color: #fbeeac;
    top: 0px;
    width: 100%;
    left: 12px;
  }

  .dropdown_animated {
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .ddown li:first-child {
    order: 1;
  }
  .ddown li:nth-child(2) {
    order: 4;
  }
  .ddown li:nth-child(3) {
    order: 2;
  }
  .ddown li:nth-child(4) {
    order: 5;
  }
  .ddown li:nth-child(5) {
    order: 3;
  }
  .ddown li:nth-child(6) {
    order: 6;
  }
}
