#career-detail img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

#career-detail .form-control {
  background: #f7f8f8;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
}

#career-detail span{
    color: red;
}