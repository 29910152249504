#contact .form-control {
  background: #f7f8f8;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  height: 60px;
  padding-left: 20px;
}

#contact .form-select {
  background: #f7f8f8;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  height: 60px;
  color: #9d9d9d;
  padding-left: 20px;
}

#contact textarea.form-control {
  height: 216px;
  padding: 20px;
}

#contact .form-control::placeholder,
textarea.form-control::placeholder {
  color: #c4c4c4;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

#contact h1,
#contact p {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  color: #272727;
}

#contact h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
}

#contact p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  width: 75%;
}

@media screen and (max-width: 990px) {
  #contact p {
    margin: 0 auto;
  }
}

#contact h2 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}

#contact iframe {
  margin: 30px 0;
  width: 90%;
  height: 230px;
}
