#aboutus-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  height: 595px;
  width: 100%;
  z-index: 10;
  background-image: url("../../../public/Asset/aboutUs/Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

#aboutus-hero h1 {
  width: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 22px;
}

#aboutus-hero p {
  width: 50%;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffe9e9;
}

@media screen and (max-width: 1025px) {
  #aboutus-hero {
    text-align: center;
  }

  #aboutus-hero h1 {
    width: 100%;
    font-size: 28px;
    line-height: 30px;
  }

  #aboutus-hero p {
    width: 100%;
    font-size: 18px;
  }

  
}
