#career .career-hero img {
  width: 100%;
}

#career .form-select {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
}

#career .career-job img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

#career .career-card {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
}

#career .career-job p {
  font-family: "Nunito", sans-serif;
}

#career .career-job a {
  color: #cccccc;
  list-style: none;
}
