#portofolio .search-form input {
  width: 35%;
  height: 59px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  margin-left: auto;
  padding: 20px;
}

#portofolio .search-form {
  margin: 30px 0;
}

#portofolio .sidebar ul {
  font-family: "Nunito", sans-serif;
  list-style: none;
  padding: 0;
  font-style: normal;
  line-height: 150%;
  margin-bottom: 50px;
}

#portofolio .sidebar ul h3 {
  font-weight: bold;
  font-size: 24px;
  color: #272727;
}

#portofolio .sidebar ul li a {
  width: max-content;
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  color: #272727;
}

#portofolio .sidebar ul li span {
  font-size: 14px;
  margin: 0;
}

#portofolio .portofolio-card {
  padding-left: 70px;
  padding-bottom: 60px;
}

#portofolio .card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0;
  text-decoration: none;
}

#portofolio .card:hover {
  
  border: 1px solid #FFCE07;
}

#portofolio .portofolio-card img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.portofolio-desc {
  padding: 20px;
}

.portofolio-desc h4 {
  font-size: 18px;
  font-weight: bold;
  color: #272727;
}

.portofolio-desc h5 {
  font-size: 14px;
  color: #c4c4c4;
}

.portofolio-desc p {
  font-size: 14px;
  color: #272727;
}

@media screen and (max-width: 768px){
  #portofolio .portofolio-card {
    padding-left: 10px;
  }
}