.service-tech {
  background-image: url("../../../Asset/TECHNOLOGY.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  margin: 80px 0 0 0;
}

.service-tech-header {
  width: 60%;
  margin: 0 auto;
}

.service-tech h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #c4c4c4;
}

.service-tech h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-align: center;

  color: #272727;
}

.service-tech p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-family: "Nunito", sans-serif;
}

@media screen and (max-width: 770px) {
  .service-tech-header {
    width: 90%;
    margin: 0 auto;
  }

  .service-tech h1 {
    font-size: 28px;
    line-height: 30px;
  }
}
