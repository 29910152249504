.service-hero {
  background-color: #ffce07;
  height: 440px;
  border-radius: 8px;
  margin: 60px 0;
}

.service-hero-wrapper {
  width: 55%;
}

.service-hero h4 {
  font-size: 18px;
}

.service-hero h1 {
  margin: 14px 0 42px 0;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 43px;
  color: #272727;
}

.service-hero img {
  margin: 0 15px;
}

@media screen and (max-width: 770px) {
  .service-hero-wrapper {
    width: 90%;
  }

  .service-hero h4 {
    font-size: 14px;
  }

  .service-hero h1 {
    font-size: 28px;
  }

  .service-hero p {
    font-size: 12px;
  }
}
