/* footer */

footer {
  font-family: "Nunito", sans-serif;
}

footer img,
footer h4 {
  margin-bottom: 30px;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer ul li{
  margin-bottom: 20px;
}

footer ul li a{
  font-family: "Nunito", sans-serif;
  text-decoration: none;
  color: #272727;
}

footer p {
  font-family: "Nunito", sans-serif;
}

footer p span {
  color: #c4c4c4;
}

.social-media {
  margin-top: 30px;
}

.social-media a {
  margin-right: 30px;
}

@media screen and (max-width: 1000px) {
  footer{
    text-align: center;
  }

  .social-media a {
    margin: 0 10px;
  }
}
