.m-header {
  margin: 30px 0;
}

.half-width {
  width: 50%;
}

.threeperfour-width {
  width: 55%;
}

.header h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #c4c4c4;
  margin-bottom: 14px;
}

.header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #272727;
}

@media screen and (max-width: 1024px) {
  .header h1 {
    font-size: 28px;
    line-height: 33px;
  }

  .header h2 {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-text-center {
    text-align: center;
  }

  .half-width {
    width: 100%;
  }

  .threeperfour-width {
    width: 100%;
  }
}
