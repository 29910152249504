.dpm-icon {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  height: 187px;
  width: 162px;
  margin: 20px;
  background-color: #fff;
}

.dpm-icon img {
  margin-bottom: 20px;
}

.dpm-icon h6 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #272727;
}

#digital-product-design h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

#digital-product-design h5 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #272727;
  margin-bottom: 20px;
}

.dpd-list {
  background: url("../../../Asset/Group\ 3.png");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
}
