#service-content {
  margin-top: 20px;
}

#service-content h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #c4c4c4;
}

#service-content h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
}

#service-content p {
  margin-bottom: 30px;
}

.service-content-img {
  display: block;
  margin-left: auto;
  max-width: 100%;
}

#service-content ul {
  list-style: none;
  padding: 0;
}

#service-content li {
  display: inline;
}

#service-content .service-content-reverse p {
  margin-bottom: 5px;
}

#service-content .service-content-reverse li {
  text-align: justify;
}

@media screen and (max-width: 1025px) {
  .service-content-img {
    margin: 0 auto;
  }
  #service-content .service-content-reverse h1 {
    font-size: 28px;
    line-height: 35px;
  }

  #service-content .service-content-reverse p {
    font-size: 14px;
  }

  
}

@media screen and (max-width: 768px) {
  #service-content {
    text-align: center;
  }
}
