.service-carousel-mobile{
    margin: 50px 0;
}
.service-carousel-mobile .carousel {
  margin: 0 100px;
}

.service-carousel-mobile .carousel-inner {
  width: 50%;
}

.service-carousel-mobile .carousel-btn {
  width: 70%;
  background-color: #fff;
  margin: 0 auto;
}

.service-carousel-mobile .carousel-btn:hover {
  background: #ffce07;
}

@media screen and (max-width: 770px) {
  .service-carousel-mobile .carousel-item{
    padding: 0;
    text-align: center;
  }
}
